<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Дашборд обменника</h3>
    </div>

    <div>

      <span><strong>Суммы:</strong></span><br>


      <span>USDT: {{ valVal("USDT") }}</span><br>
      <span>BTC: {{ valVal("BTC") }}</span><br>
      <span>ETH: {{ valVal("ETH") }}</span><br>
      <span>BNB: {{ valVal("BNB") }}</span><br>
      <span>TON: {{ valVal("TON") }}</span><br>
      <span>LTC: {{ valVal("LTC") }}</span><br>


      <span><strong>Курсы:</strong></span><br>

      <div style="width: 250px;">

        <div v-for="c in courses" :key="c.title">

          <v-text-field append-icon="mdi-content-save" @click:append="coursesave($event, c)" :label="convertCurrencyPairName(c.title)"
                        v-model="c.rate" required></v-text-field>

        </div>

      </div>

      <span><strong>Комиссии:</strong></span><br>

      <span>Комиссии сетей:</span><br>

      <div style="width: 250px;">
        <div v-for="c in crypto_networks" :key="c.title">
          <v-text-field append-icon="mdi-content-save" @click:append="commsave($event, c)" :label="c.title"
                        v-model="c.rate" required></v-text-field>
        </div>

        <span>Комиссия наличные:</span><br>

        <div>
          <v-text-field append-icon="mdi-content-save" @click:append="cash_commsave('Покупка',cash_commission1)"
                        label="Покупка"
                        v-model="cash_commission1" required></v-text-field>
        </div>

        <div>
          <v-text-field append-icon="mdi-content-save" @click:append="cash_commsave('Продажа',cash_commission2)"
                        label="Продажа"
                        v-model="cash_commission2" required></v-text-field>
        </div>

        <span>Комиссия конвертации:</span><br>

        <div>
          <v-text-field append-icon="mdi-content-save" @click:append="conv_commsave(conv_commission)"
                        label="Конвертации"
                        v-model="conv_commission" required></v-text-field>
        </div>

      </div>

    </div>

  </div>
</template>

<script>

import gql from "graphql-tag";

import Snackbar from "../components/controls/Snackbar.vue";


import {fetcher, getHeaders} from "../store";


export default {
  name: 'ExchangeConfig',

  components: {Snackbar},

  apollo: {


    crypto_networks: {
      query: gql`
query MyQuery {
  crypto_networks {
    commission
    title
    id
  }
}
        `,
      result({data}) {
        this.crypto_networks = data.crypto_networks;
      },
    },


    courses: {
      query: gql`
query MyQuery {
  courses(where: {type: {_eq: "crypto2currency"}}, order_by: {title: asc}) {
    rate
    title
    type
    currency_from
    currency_to
    bank_id
  }
}
        `,
      result({data}) {
        this.courses = data.courses;

        this.courses.forEach(c => {
          if (c.title === "USDT2rub_buy")
            this.USDT2rub_buy = c.rate
          if (c.title === "USDT2rub_sale")
            this.USDT2rub_sale = c.rate

        })

      },
    },


    summs: {
      query: gql`
query summs {
  USDT: balances_aggregate(where: {title: {_eq: "USDT"}}) {
    aggregate {
      sum {
        value
      }
    }
  }
  BTC: balances_aggregate(where: {title: {_eq: "BTC"}}) {
    aggregate {
      sum {
        value
      }
    }
  }
  ETH: balances_aggregate(where: {title: {_eq: "ETH"}}) {
    aggregate {
      sum {
        value
      }
    }
  }

  BNB: balances_aggregate(where: {title: {_eq: "BNB"}}) {
    aggregate {
      sum {
        value
      }
    }
  }

    TON: balances_aggregate(where: {title: {_eq: "TON"}}) {
    aggregate {
      sum {
        value
      }
    }
  }

    LTC: balances_aggregate(where: {title: {_eq: "LTC"}}) {
    aggregate {
      sum {
        value
      }
    }
  }


}

        `,
      result({data}) {
        this.summs = data;
      },
    },
  },


  data: () => ({
    tag: 'test',
    summs: [],
    courses: [],
    value: null,
    USDT2rub_buy: -1,
    USDT2rub_sale: -1,

    crypto_networks: null,

    cash_commission1: 0,

    cash_commission2: 0,

    conv_commission: 0,

  }),

  methods: {

    convertCurrencyPairName(pairName) {
      const currencyPairNames = {
        "USDT2rub_buy":  "Покупка USDT в RUB",
        "USDT2kzt_buy":  "Покупка USDT в KZT",
        "USDT2rub_sale": "Продажа USDT в RUB",
        "USDT2kzt_sale": "Продажа USDT в KZT",
        "USDT2try_buy":  "Покупка USDT в TRY",
        "USDT2try_sale": "Продажа USDT в TRY",
        "USDT2uzs_buy":  "Покупка USDT в UZS",
        "USDT2uzs_sale": "Продажа USDT в UZS",
      };

      if (pairName in currencyPairNames) {
        return currencyPairNames[pairName];
      } else {
        return "Неподдерживаемая пара обмена";
      }
    },

    valVal(val) {
      if (!val)
        return "---"

      if (!this.summs || this.summs.length === 0)
        return "---"

      return this.summs[val] && this.summs[val].aggregate ? this.summs[val].aggregate.sum.value : "--"
    },

    async conv_commsave(amount) {
      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({amount: amount})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/commission/conversion`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    async cash_commsave(operation_type, amount) {
      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({operation_type: operation_type, amount: amount})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/commission/cash`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    async commsave(i, b) {
      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({amount: b.commission})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/commission/network/${b.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async coursesave(e,c ) {
      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({course_amount: c.rate })
      };

      let type = c.title.includes('buy') ? 'buy' : 'sale'

      let currency = c.title.substring(5, 8)

      let url = `${process.env.VUE_APP_EXCHANGE_API}/courses/${currency}/${type}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    async USDT2rub_buyClick() {
      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({course_amount: this.USDT2rub_buy})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/courses/rub_buy`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async USDT2rub_saleClick() {
      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({course_amount: this.USDT2rub_sale})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/courses/rub_sale`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },


    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

  },

}
</script>
