var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c("div", [
        _vm._m(1),
        _c("br"),
        _c("span", [_vm._v("USDT: " + _vm._s(_vm.valVal("USDT")))]),
        _c("br"),
        _c("span", [_vm._v("BTC: " + _vm._s(_vm.valVal("BTC")))]),
        _c("br"),
        _c("span", [_vm._v("ETH: " + _vm._s(_vm.valVal("ETH")))]),
        _c("br"),
        _c("span", [_vm._v("BNB: " + _vm._s(_vm.valVal("BNB")))]),
        _c("br"),
        _c("span", [_vm._v("TON: " + _vm._s(_vm.valVal("TON")))]),
        _c("br"),
        _c("span", [_vm._v("LTC: " + _vm._s(_vm.valVal("LTC")))]),
        _c("br"),
        _vm._m(2),
        _c("br"),
        _c(
          "div",
          { staticStyle: { width: "250px" } },
          _vm._l(_vm.courses, function (c) {
            return _c(
              "div",
              { key: c.title },
              [
                _c("v-text-field", {
                  attrs: {
                    "append-icon": "mdi-content-save",
                    label: _vm.convertCurrencyPairName(c.title),
                    required: "",
                  },
                  on: {
                    "click:append": function ($event) {
                      return _vm.coursesave($event, c)
                    },
                  },
                  model: {
                    value: c.rate,
                    callback: function ($$v) {
                      _vm.$set(c, "rate", $$v)
                    },
                    expression: "c.rate",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
        _vm._m(3),
        _c("br"),
        _c("span", [_vm._v("Комиссии сетей:")]),
        _c("br"),
        _c(
          "div",
          { staticStyle: { width: "250px" } },
          [
            _vm._l(_vm.crypto_networks, function (c) {
              return _c(
                "div",
                { key: c.title },
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-content-save",
                      label: c.title,
                      required: "",
                    },
                    on: {
                      "click:append": function ($event) {
                        return _vm.commsave($event, c)
                      },
                    },
                    model: {
                      value: c.rate,
                      callback: function ($$v) {
                        _vm.$set(c, "rate", $$v)
                      },
                      expression: "c.rate",
                    },
                  }),
                ],
                1
              )
            }),
            _c("span", [_vm._v("Комиссия наличные:")]),
            _c("br"),
            _c(
              "div",
              [
                _c("v-text-field", {
                  attrs: {
                    "append-icon": "mdi-content-save",
                    label: "Покупка",
                    required: "",
                  },
                  on: {
                    "click:append": function ($event) {
                      return _vm.cash_commsave("Покупка", _vm.cash_commission1)
                    },
                  },
                  model: {
                    value: _vm.cash_commission1,
                    callback: function ($$v) {
                      _vm.cash_commission1 = $$v
                    },
                    expression: "cash_commission1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("v-text-field", {
                  attrs: {
                    "append-icon": "mdi-content-save",
                    label: "Продажа",
                    required: "",
                  },
                  on: {
                    "click:append": function ($event) {
                      return _vm.cash_commsave("Продажа", _vm.cash_commission2)
                    },
                  },
                  model: {
                    value: _vm.cash_commission2,
                    callback: function ($$v) {
                      _vm.cash_commission2 = $$v
                    },
                    expression: "cash_commission2",
                  },
                }),
              ],
              1
            ),
            _c("span", [_vm._v("Комиссия конвертации:")]),
            _c("br"),
            _c(
              "div",
              [
                _c("v-text-field", {
                  attrs: {
                    "append-icon": "mdi-content-save",
                    label: "Конвертации",
                    required: "",
                  },
                  on: {
                    "click:append": function ($event) {
                      return _vm.conv_commsave(_vm.conv_commission)
                    },
                  },
                  model: {
                    value: _vm.conv_commission,
                    callback: function ($$v) {
                      _vm.conv_commission = $$v
                    },
                    expression: "conv_commission",
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Дашборд обменника")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("strong", [_vm._v("Суммы:")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("strong", [_vm._v("Курсы:")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("strong", [_vm._v("Комиссии:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }